import React from "react";
import ListingsAtLocation from "../components/ListingsAtLocation";

export default ({ location }) => {
  console.log("location", location);
  const queryParams = new URLSearchParams(location.search);
  console.log("queryParams", queryParams);
  const lat = queryParams.get("lat");
  const lon = queryParams.get("lon");

  return <ListingsAtLocation location={{
    coords: {
      latitude: Number(lat),
      longitude: Number(lon),
    }
  }} />
}

