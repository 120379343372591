import React from "react";
import {Box, Input} from "theme-ui";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

type SearchBoxProps = {
  value: string,
  onChange: (text: string) => void
}

export default ({ value, onChange }: SearchBoxProps) => {
  return (
    <Box sx={{ position: 'relative', transition: "0.3s all" }}>
      <Input value={value} onChange={e => onChange(e.target.value)}
        sx={{ pl: 4, py: 2, lineHeight: 'normal' }}
      />
      <Box as={SearchIcon}
        sx={{
          position: 'absolute',
          left: '8px',
          top: 0,
          bottom: 0,
          margin: 'auto',
        }}
      />
      { value !== "" &&
        <Box as={ClearIcon}
          onClick={() => onChange("")}
          sx={{
            position: 'absolute',
            right: '8px',
            top: 0,
            bottom: 0,
            margin: 'auto',
            cursor: 'pointer'
          }}
        />
      }
    </Box>
  );
}

