import React, {useEffect, useRef, useState} from "react";
import {navigate} from "gatsby";
import {Box, Card, Flex, Heading, Image, Link, Text} from "theme-ui";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvent,
  useMap,
  // useMap,
} from "react-leaflet";
import useHasMounted from "../lib/useHasMounted";
import useDebounce from "../lib/useDebounce";
import LoadingIndicator from "./LoadingIndicator";
import SearchBox from "./SearchBox";
import {LatLngBounds} from "leaflet";
import useAuthenticatedFetch from "../useAuthenticatedFetch";
import {endpoint} from "../lib/endpoint";

type SimplifiedLocation = {
  coords: {
    latitude: number,
    longitude: number
  }
};

type ListingsAtLocationProps = {
  location: SimplifiedLocation,
}

const EventsComponent = ({ onNewLocationPicked, onNewBounds }) => {
  const map = useMap();
  const [bounds, setBounds] = useState<LatLngBounds>(undefined);
  const boundsMemo = useDebounce(bounds, 300);

  useEffect(() => {
    if (map && map.getBounds()) {
      setBounds(map.getBounds());
    }
  }, [map]);

  useEffect(() => {
    if (boundsMemo) {
      onNewBounds(boundsMemo);
    }
  }, [boundsMemo]);

  const map3 = useMapEvent('move', _e => {
    console.log("move", map3.getBounds());
    setBounds(map3.getBounds());
  });
  return null;
}

function transformBounds(bounds: LatLngBounds | undefined): string {
  if (!bounds) {
    return '0,0,0,0';
  }
  return `${bounds.getNorthEast().lat},${bounds.getNorthEast().lng},${bounds.getSouthWest().lat},${bounds.getSouthWest().lng}`;
}

type Listing = {
  guid: string,
  title: string,
  ends_at: string,
  description: string,
  humanized_price: string,
  image_thumb_url: string
}

type Venue = {
  name: string,
  id: number,
  latitude: number,
  longitude: number,
  listings: Listing[],
};

const VenueWithListings = ({ venue }: { venue: Venue }) => {
  return (
    <Box key={venue.id}>
      <Heading sx={{py: 3}}>{venue.name}</Heading>
      { venue.listings.map(listing => (
        <Box key={listing.guid} sx={{pb: 2}}>
          <Card sx={{mr: 2, p: 2, }} key={listing.guid} onClick={() => navigate(`/listings/${listing.guid}`)}>
            <Flex sx={{ justifyContent: 'space-between' }}>
              <Box>
                <Heading as="h3">{listing.title} | {listing.humanized_price} </Heading>
                <Text>{listing.description}</Text>
              </Box>
              { listing.image_thumb_url &&
                <Box>
                  <Image src={listing.image_thumb_url} variant="listingThumb" />
                </Box>
              }
            </Flex>
          </Card>
        </Box>
      ))}
    </Box>
  );
};


const ListingsAtLocation = ({ location }: ListingsAtLocationProps) => {
  const hasMounted = useHasMounted();
  const { latitude, longitude } = location.coords;
  const [bounds, setBounds] = useState<LatLngBounds | undefined>(undefined);
  const [fetchVenues, _] = useAuthenticatedFetch(`${endpoint}/venues?bounds=${transformBounds(bounds)}`);
  const [fetchedVenues, setFetchedVenues] = useState<Venue[]>([]);
  const [venues, setVenues] = useState<Venue[]>([]);
  const [isSearchEnabled, setSearchEnabled] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [filteredVenues, setFilteredVenues] = useState<Venue[]>([]);
  const venueSectionsRefs = useRef<HTMLDivElement | null[]>([]);

  useEffect(() => {
    const totalNumberOfListings = venues.reduce((counter, venue) => counter + venue.listings.length, 0);
    setSearchEnabled(totalNumberOfListings > 3);
    if (totalNumberOfListings <= 3) {
      setFilterText("");
    }
  }, [venues]);

  useEffect(() => {
    if (filterText === "") {
      setFilteredVenues(venues);
    } else {
      setFilteredVenues(venues.map(venue => {
        return {
          ...venue,
          listings: venue.listings.filter(listing =>
              listing.title.toLowerCase().match(filterText.toLowerCase()) ||
              listing.description?.toLowerCase().match(filterText.toLowerCase())),
        };
      }).filter(v => v.listings.length > 0));
    }
  }, [venues, filterText]);

  useEffect(() => {
    const map = {};
    let changed = false;
    for (const venue of venues) {
      map[venue.id] = venue;
    }
    for (const venue of fetchedVenues) {
      if (!map[venue.id]) {
        changed = true;
        map[venue.id] = venue;
      }
    }
    if (changed) {
      setVenues(Object.values(map));
    }
  }, [venues, fetchedVenues]);

  useEffect(() => {
    if (!bounds) {
      return;
    }
    console.log("load venues with listings for bounds", bounds);
    console.log("location", window?.location);
    if (window?.location) {
      const queryParams = new URLSearchParams(window.location.search);
      console.log("queryParams", queryParams);
      queryParams.set("bbox", bounds.toBBoxString());
      // TODO: this is experimental, must try deployed
      navigate(`${window.location.pathname}?${queryParams.toString()}`);
    }
    fetchVenues().then(result => {
      return result.json();
    }).then(data => {
      console.info("data", data);
      setFetchedVenues(data.venues);
    }).catch(err => {
      alert("Unable to load listings, please refresh to retry. message: " + err.message)
    });

  }, [bounds, fetchVenues]);

  const isBrowser = typeof window !== "undefined"
  if (hasMounted && isBrowser) {
    return (
      <>
        <MapContainer style={{ height: 300 }} center={[latitude, longitude]} zoom={13} scrollWheelZoom={true} whenReady={() => {
          console.info("whenReady, e");
        }} >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <EventsComponent onNewBounds={(bounds: LatLngBounds) => {
            setBounds(bounds);
          }} onNewLocationPicked={(lat, lng) => {
            console.log({ coords: { latitude: lat, longitude: lng }});
          }}/>
          { filteredVenues.map(({ name, id, latitude, longitude, listings }, venueIx) => (
            <Marker key={id} position={[latitude, longitude]}>
              <Popup>
                <Link sx={{
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }} onClick={() => venueSectionsRefs.current[venueIx]?.scrollIntoView()}>
                  {name}: {listings.length} listing{listings.length !== 1 ? 's' : ''}
                </Link>
              </Popup>
            </Marker>
          )) }
        </MapContainer>
        <Box sx={{ pt: 3 }}>
          { venues.length === 0 &&
            <Text>Nothing found? Try to zoom out.</Text>
          }
          { isSearchEnabled &&
            <SearchBox value={filterText} onChange={text => setFilterText(text)} />
          }
          { filteredVenues.map((venue, venueIx) => (
            <div key={venue.id} ref={el => venueSectionsRefs.current[venueIx] = el}>
              <VenueWithListings venue={venue} />
            </div>
          ))}
        </Box>
      </>
    );
  } else {
    return <LoadingIndicator />
  }
}

export default ListingsAtLocation;
